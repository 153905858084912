.path {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
}
.path > * {
    flex-shrink: 0;
}

.spacer {
    flex-grow: 1;
}

.header {
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 22px;
    width: 100%;
    min-height: 60px;
    border-bottom: 1px solid #ababab;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 4px;
    font-weight: 500;
}

.path svg {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 0.6em;
    margin-top: 4px;
}
