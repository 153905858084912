html {

}

body {
  margin: 0;
  padding: 0;
  background: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#sizeTest
{
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  top: 0px;
  white-space: nowrap; /* Thanks to Herb Caudill comment */
}

body {
}
#root {
  box-sizing: border-box;
}

.ChordFile_line__1qv6a {

}

.ChordFile_chord__2LR5n {
 font-weight: 600;
}

.ChordFile_word__8G4II {

}

.ChordFile_word__8G4II + .ChordFile_chord__2LR5n {

}

.ChordFile_fileContainer__tYlQY {
 font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
 height:auto;
 display: inline-block;
 background: white;
 color: black;
 width: auto;
 white-space: pre;
 text-align: left;
 box-sizing: border-box;
}

.ChordFile_fileContainer__tYlQY {
 overflow: hidden;
}


.ChordFile_pairedLine__3n7wx {
 -webkit-column-break-inside: avoid;
 page-break-inside: avoid;
 page-break-inside: avoid;
 break-inside: avoid;
}

.Browser_browserContainer__3jKKi {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    opacity: 0;
    pointer-events: none;
}

.Browser_open__26D12 {
    opacity: 1;
    pointer-events: auto;
}


.Browser_browser__2ASh_ {
    left: 0;
    top: 0;
    width: 50%;
    height: 75%;
    border: 1px solid #dadada;
    box-sizing: border-box;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    border-radius: 6px;
    background: white;
}

@media (max-width: 900px) {
    .Browser_browser__2ASh_ {
        width: 95%;
        height: 90%;
    }
}

.Browser_entry__1ugqp {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 17px;
    font-weight: 400;
    padding-top: 5px;
    padding-left: 10px;
    min-height: 44px;
    box-sizing: border-box;
    padding-bottom: 5px;
    border-bottom: 1px solid #dedede;
    width: 100%;
    text-align: left;
    text-decoration: none;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}


.Browser_entry__1ugqp:hover, .Browser_pathComponent__3BIG9:hover, .Browser_entry__1ugqp:focus, .Browser_pathComponent__3BIG9:focusa {
    cursor: pointer;
    background: #f0f0f0;
    color: #000;
    border-radius: 6px;
}

.Browser_activePathComponent__3GyJv {
    background: #f0f0f0;
}

.Browser_pathComponent__3BIG9 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 6px;
}

@media (max-width: 600px) {
    .Browser_pathComponent__3BIG9 {
        font-size: 16px;
    }
}
.Browser_entry__1ugqp:hover{
    text-decoration: underline;
}


.Browser_entry__1ugqp svg {
    margin-right: 6px;
    min-width: 20px;
}

.Browser_pathComponent__3BIG9, .Browser_entry__1ugqp {
    color: #333;
}

.Browser_files__-pEBn {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.DialogHeader_path__4GaMA {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
}
.DialogHeader_path__4GaMA > * {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.DialogHeader_spacer__3Lx7y {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.DialogHeader_header__2T7UJ {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    font-size: 22px;
    width: 100%;
    min-height: 60px;
    border-bottom: 1px solid #ababab;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 4px;
    font-weight: 500;
}

.DialogHeader_path__4GaMA svg {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 0.6em;
    margin-top: 4px;
}


.CloseButton_closeButton__1teZ8 {
    min-width: 44px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    margin-right: 0px;
}

.CloseButton_closeButton__1teZ8 button {
    min-width: 44px;
    height: 44px;
}

.App_app__3XG0B {
    box-sizing: border-box;
}

.App_margin__2f9Xg {
    margin-right: 20px;
}


body {
    background-color: #333333;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
}


.App_header__2EsjT {
    color: white;
    width: calc(100% + 40px);
    margin-left: -40px;
    background: #333333;
    margin-bottom: 20px;
    padding: 10px;
    padding-left: 40px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.App_header__2EsjT > * + * {
}

.App_containerDimmed__I923Q {
    height: 100vh;
    overflow: hidden;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

.App_container__Wu10u {
    min-height: calc(100vh - 60px);
    width: 100%;
    background: white;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

button, .App_value__3tbnj {
    -moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
    -webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
    background-color:#ededed;
    border-radius:6px;
    border:1px solid #dcdcdc;
    cursor:pointer;
    color:#333;
    font-size:15px;
    font-weight:bold;
    padding:6px 16px;
    text-decoration:none;
    text-shadow:0px 1px 0px #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    box-sizing: border-box;
}

.App_fileButton__31d8b {
    min-width: 80px;
    margin-bottom: 20px;
}
button {
    min-width: 44px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@media (max-width: 900px) {
    button, .App_value__3tbnj {
        padding: 2px 5px;
        font-size: 12px;
    }
}
@media (max-width: 420px) {
    button, .App_value__3tbnj {
        min-width: 34px;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        height: 44px;
    }

    .App_margin__2f9Xg {
        margin-right: 10px;
    }
}

@media (max-width: 340px) {
    button, .App_value__3tbnj {

    }

    .App_margin__2f9Xg {
        margin-right: 5px;
    }
}



.App_value__3tbnj {
    border-radius: 0;
}

button:hover {
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #dfdfdf), color-stop(1, #ededed));
    background:-webkit-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
    background:linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#ededed',GradientType=0);
    background-color:#dfdfdf;
}
button:active {
    position:relative;
    top:1px;
}

.App_buttonRight__3Ki25 {
    border-radius: 0 6px 6px 0;
}

.App_buttonLeft__1B5sk {
    border-radius: 6px 0 0 6px;
}

.App_buttonLeft__1B5sk, .App_buttonRight__3Ki25 {
    width: 100px;
    height: 60px;
}

.App_overlayContainer__2N-9X {
    width: 100%;
    height: calc(100%);
    position: absolute;
    left: 0;
    top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}


.App_dialogContainer__2npzU {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.App_touchContainer__peuKM {
    position: relative;
    width: 100%;
    height: 100%;
}

.App_dialog__1iwug {
    left: 0;
    top: 0;
    width: 220px;
    border: 1px solid #dadada;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    border-radius: 6px;
    background: white;
}


.App_dialog__1iwug input {
    width: 100%;
}

.App_dialogBody__1ErIR {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.App_dialogButtonContainer__1OwTa {
    width: 100%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    padding: 10px;
}


.App_incButton__4l4sW {
    color: #333;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
}

.App_incTop__2QKv2 {
    display: -webkit-flex;
    display: flex;
}

button {
    height: 44px;
}

.App_description__3tM9_ {
    display: -webkit-flex;
    display: flex;

    -webkit-flex-direction: column;

            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 15px;
}

.App_description__3tM9_ img {
    -webkit-align-self: center;
            align-self: center;
    margin-bottom: 60px;
    margin-top: 20px;
    width: 115px;
    height: 167px;
}

.App_incBottom__R0_FC {
    width: 100%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    text-align: center;
    font-size: 12px;
    margin-top: 4px;
    color: white;
}

.App_incLeft__27OEf, .App_incRight__WGOdc {
    width: 55px;
    height: 44px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

}

button {
    outline-width: 0;
}

* {
    touch-action: manipulation;
}

.Spinner_lds-spinner__2Dir0 {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.Spinner_lds-spinner__2Dir0 div {
    -webkit-transform-origin: 32px 32px;
            transform-origin: 32px 32px;
    -webkit-animation: Spinner_lds-spinner__2Dir0 1.2s linear infinite;
            animation: Spinner_lds-spinner__2Dir0 1.2s linear infinite;
}
.Spinner_lds-spinner__2Dir0 div:after, .Spinner_lds-spinner-dark__30xDJ div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #fff;
}

.Spinner_lds-spinner-dark__30xDJ div:after {
    background: #333;
}


.Spinner_lds-spinner__2Dir0 div:nth-child(1) {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(2) {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(3) {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(4) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(5) {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(6) {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(7) {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(8) {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg);
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(9) {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg);
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(10) {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(11) {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg);
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
}
.Spinner_lds-spinner__2Dir0 div:nth-child(12) {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}
@-webkit-keyframes Spinner_lds-spinner__2Dir0 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes Spinner_lds-spinner__2Dir0 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}



