.browserContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
}

.open {
    opacity: 1;
    pointer-events: auto;
}


.browser {
    left: 0;
    top: 0;
    width: 50%;
    height: 75%;
    border: 1px solid #dadada;
    box-sizing: border-box;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 6px;
    background: white;
}

@media (max-width: 900px) {
    .browser {
        width: 95%;
        height: 90%;
    }
}

.entry {
    user-select: none;
    font-size: 17px;
    font-weight: 400;
    padding-top: 5px;
    padding-left: 10px;
    min-height: 44px;
    box-sizing: border-box;
    padding-bottom: 5px;
    border-bottom: 1px solid #dedede;
    width: 100%;
    text-align: left;
    text-decoration: none;
    display: flex;
    align-items: center;
}


.entry:hover, .pathComponent:hover, .entry:focus, .pathComponent:focusa {
    cursor: pointer;
    background: #f0f0f0;
    color: #000;
    border-radius: 6px;
}

.activePathComponent {
    background: #f0f0f0;
}

.pathComponent {
    display: flex;
    justify-content: center;
    padding: 6px;
}

@media (max-width: 600px) {
    .pathComponent {
        font-size: 16px;
    }
}
.entry:hover{
    text-decoration: underline;
}


.entry svg {
    margin-right: 6px;
    min-width: 20px;
}

.pathComponent, .entry {
    color: #333;
}

.files {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}
