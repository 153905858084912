
.closeButton {
    min-width: 44px;
    flex-shrink: 0;
    margin-right: 0px;
}

.closeButton button {
    min-width: 44px;
    height: 44px;
}
