.app {
    box-sizing: border-box;
}

.margin {
    margin-right: 20px;
}


body {
    background-color: #333333;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
}


.header {
    color: white;
    width: calc(100% + 40px);
    margin-left: -40px;
    background: #333333;
    margin-bottom: 20px;
    padding: 10px;
    padding-left: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header > * + * {
}

.containerDimmed {
    height: 100vh;
    overflow: hidden;
    filter: brightness(0.5);
}

.container {
    min-height: calc(100vh - 60px);
    width: 100%;
    background: white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
}

button, .value {
    -moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
    -webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
    background-color:#ededed;
    -moz-border-radius:6px;
    -webkit-border-radius:6px;
    border-radius:6px;
    border:1px solid #dcdcdc;
    cursor:pointer;
    color:#333;
    font-size:15px;
    font-weight:bold;
    padding:6px 16px;
    text-decoration:none;
    text-shadow:0px 1px 0px #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.fileButton {
    min-width: 80px;
    margin-bottom: 20px;
}
button {
    min-width: 44px;
    user-select: none;
}

@media (max-width: 900px) {
    button, .value {
        padding: 2px 5px;
        font-size: 12px;
    }
}
@media (max-width: 420px) {
    button, .value {
        min-width: 34px;
        flex-grow: 1;
        height: 44px;
    }

    .margin {
        margin-right: 10px;
    }
}

@media (max-width: 340px) {
    button, .value {

    }

    .margin {
        margin-right: 5px;
    }
}



.value {
    border-radius: 0;
}

button:hover {
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #dfdfdf), color-stop(1, #ededed));
    background:-moz-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
    background:-webkit-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
    background:-o-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
    background:-ms-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
    background:linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#ededed',GradientType=0);
    background-color:#dfdfdf;
}
button:active {
    position:relative;
    top:1px;
}

.buttonRight {
    border-radius: 0 6px 6px 0;
}

.buttonLeft {
    border-radius: 6px 0 0 6px;
}

.buttonLeft, .buttonRight {
    width: 100px;
    height: 60px;
}

.overlayContainer {
    width: 100%;
    height: calc(100%);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


.dialogContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.touchContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.dialog {
    left: 0;
    top: 0;
    width: 220px;
    border: 1px solid #dadada;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 6px;
    background: white;
}


.dialog input {
    width: 100%;
}

.dialogBody {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.dialogButtonContainer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 10px;
}


.incButton {
    color: #333;
    flex-direction: column;
    display: flex;
}

.incTop {
    display: flex;
}

button {
    height: 44px;
}

.description {
    display: flex;

    flex-direction: column;
    justify-content: center;
    padding: 15px;
}

.description img {
    align-self: center;
    margin-bottom: 60px;
    margin-top: 20px;
    width: 115px;
    height: 167px;
}

.incBottom {
    width: 100%;
    user-select: none;
    pointer-events: none;
    text-align: center;
    font-size: 12px;
    margin-top: 4px;
    color: white;
}

.incLeft, .incRight {
    width: 55px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

}

button {
    outline-width: 0;
}

* {
    touch-action: manipulation;
}
