.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#sizeTest
{
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  top: 0px;
  white-space: nowrap; /* Thanks to Herb Caudill comment */
}

body {
}
#root {
  box-sizing: border-box;
}
