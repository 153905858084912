.line {

}

.chord {
 font-weight: 600;
}

.word {

}

.word + .chord {

}

.fileContainer {
 font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
 height:auto;
 display: inline-block;
 background: white;
 color: black;
 width: auto;
 white-space: pre;
 text-align: left;
 box-sizing: border-box;
}

.fileContainer {
 overflow: hidden;
}


.pairedLine {
 -webkit-column-break-inside: avoid;
 page-break-inside: avoid;
 break-inside: avoid;
}
